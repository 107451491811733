import React from 'react';

import BuisBeneden from '../assets/buis_beneden.jpg';
import Kraantje from '../assets/kraantje.jpg';

export default class Water extends React.Component {

    render() {
        return (
            <div className="Pomp">
                <h1>
                    Hoe water van de tonnen te nemen?
                </h1>

                <p>
                    Om rechtstreeks water van de tonnen te nemen moet je volgende stappen volgen
                </p>

                <div className="photos">
                    <img src={BuisBeneden} alt={"Buis Beneden"} className="image" />
                    <img src={Kraantje} alt={"Kraantje"} className="image" />
                </div>

                <h1>
                    Het licht aan doen:
                </h1>

                <p>
                    <span className="list-number">1)</span> Voor het licht beneden in de werkhoek doe de schakelaar rechts van de grote lichten aan (in smalle doorgang).

                    <br />

                    <span className="list-number">2)</span> Voor het licht boven gebruik de schakelaar naast de trap naar boven tegen het raam
                </p>

                <h1>
                    Water nemen
                </h1>

                <p>
                    <span className="list-number">1)</span> Steek beneden de buis in de ton. Zorg dat de ton stabiel staat & buis diep genoeg zit.

                    <br />

                    <span className="list-number">2)</span> Zet boven het kleine kraantje onderaan <span className="pomp-letter">een beetje</span> open. (zie foto)

                    <br />

                    <span className="list-number">3)</span> Hou de ton in het oog en zet tijdig het kraantje toe.

                    <br /><br />

                    De ton in de foto kan je gebruiken voor 2 paarden. Je moet de bakken dus niet volledig vullen in de stal.

                    <br />

                    Als je met 2 bent kan je ook de grote blauwe zak en/of kleine biddontjes gebruiken zodat de 2de persoon beneden de buis kan vast houden.
                </p>
            </div>
        );
    }
}