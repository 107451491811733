import React from 'react';
import { addYears } from 'date-fns';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import Snowflakes from './components/Snowflakes';
import {ToastContainer, toast} from 'react-toastify';
import Cookies from 'js-cookie';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';

import Calendar from './pages/Calendar';
import Medicatie from './pages/Medicatie';
import Pomp from './pages/Pomp';
import Water from './pages/Water';

import Logo from './assets/logo.jpg';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

class App extends React.Component {

  constructor(props) {
    super(props);

    const key = Cookies.get('login_key') || '';
    const theme = Cookies.get('theme');

    this.state = {
      user: null,
      theme: theme,

      loader: false,

      inputKey: key,
    }

    if (key) {
      setTimeout(this.login.bind(this), 1);
    }
  }

  changeTheme(event) {
    this.setState({
      theme: event.target.value
    });
    Cookies.set('theme', event.target.value, { expires: new Date('2100-01-01')})
  }

  render() {
    return (
      <div className={"App " + this.state.theme}>
        { this.state.theme === 'Christmas' ? <Snowflakes /> : ''}
        <Router>
          { this.state.user ?
            (<header>
              <h1>Welkom {this.state.user.username}</h1>
              <div className="menu">
                <Link className="menu-item" to="/">Kalender</Link>
                <Link className="menu-item" to="/medicatie">Medicatie</Link>
                <Link className="menu-item" to="/water">Uitleg Water</Link>
                <Link className="menu-item" to="/pomp">Uitleg Pomp</Link>
                <Link className="menu-item logout" to={"/"} onClick={this.logout.bind(this)}>Afmelden</Link>
              </div>
            </header>)
          : null}
          {this.renderData()}
          <ToastContainer/>
          <div>
            <h1>Thema:</h1>
            <select className="select" value={this.state.theme} onChange={this.changeTheme.bind(this)}>
              <option value="">Standaard</option>
              <option value="Dark">Donker</option>
              <option value="Christmas">Winter</option>
              <option value="Pink">Roze</option>
              <option value="KV">KV Mechelen</option>
            </select>
          </div>
          <img src={Logo} alt="" className="logo" />
        </Router>
      </div>
    );
  }

  renderData() {
    if (this.state.loader) {
      return this.renderLoader();
    } else if (!this.state.user) {
      return this.renderLogin();
    } else {
      return this.renderRouter();
    }
  }

  renderLoader() {
    return (
      <div className="loader">
        <Loader type="Hearts" color="#94284a" height={160} width={160} />
      </div>
    )
  }

  renderLogin() {
    return (
      <form onSubmit={this.login.bind(this)}>
        <h2>Geef je login key in</h2>
        <input className="input" placeholder="login key" value={this.state.inputKey} onChange={this.changeKey.bind(this)}/>

        <div className="add-buttons">
          <div className="button" onClick={this.login.bind(this)}>
            Inloggen
          </div>
        </div>

      </form>
    )
  }

  renderRouter() {
    return (
        <Switch>

          <Route exact path="/medicatie" component={() => <Medicatie user={this.state.user} />} />
          <Route exact path="/pomp" component={Pomp} />
          <Route exact path="/water" component={Water} />
          <Route component={() => <Calendar user={this.state.user} />} />

        </Switch>
    )
  }

  changeKey(event) {
    this.setState({ inputKey: event.target.value })
  }

  login() {
    this.setState({
      loader: true
    }, () => {
      axios.post('/api/login', {
        key: this.state.inputKey
      }).then(response => {
        Cookies.set('login_key', response.data.key, { expires: addYears(new Date(), 1)});
        this.setState({
          user: {
            key: response.data.key,
            username: response.data.username,
            id: response.data.user_id
          },
          inputKey: '',
          loader: false
        })
      }).catch(err => {
        console.log(err);
        toast.error(err.response.data.reason);
        this.setState({
          loader: false
        })
      })
    })
  }

  logout() {
    Cookies.remove('login_key');
    this.setState({
      user: null
    })
  }
}


export default App;
